import React from "react";
import "./App.scss";
import "@wfp/ui/src/globals/scss/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import paths from "./paths";
import Home from "./pages/Home/Home";
import { TopNav } from "./components/Nav";
import Meeting from "./pages/Meeting/Meeting";
import AttendeesReport from "./pages/AttendeesReport/AttendeesReport";
import MeetingAttendeesEdit from "./pages/MeetingAttendeesEdit/MeetingAttendeesEdit";
import MeetingAttributesEdit from "./pages/MeetingAttributesEdit/MeetingAttributesEdit";
import ToastNotificationWrapper from "./components/ToastNotificationContext";

function App() {
  return (
    <ToastNotificationWrapper>
      <div className="app">
        <Router>
          <TopNav />
          <Routes>
            <Route path={paths.match.index} Component={Home} />
            <Route
              path={paths.match.meetingReport}
              Component={AttendeesReport}
            />
            <Route
              path={paths.match.meetingAttendees}
              Component={MeetingAttendeesEdit}
            />
            <Route
              path={paths.match.meetingAttributes}
              Component={MeetingAttributesEdit}
            />
            <Route path={paths.match.meeting} Component={Meeting} />
          </Routes>
        </Router>
      </div>
    </ToastNotificationWrapper>
  );
}

export default App;
