import React from "react";
import styles from "./styles.module.scss";
import MeetingHeader from "../../components/Meetings/MeetingHeader";
import MeetingInfo from "../../components/Meetings/MeetingInfo/MeetingInfo";
import { meetings } from "../../data";
import { Button, FormLabel, SingleDatePickerInput, Text } from "@wfp/ui";
import cn from "classnames";
import Select from "react-select";
import { SingleDatePicker } from "react-dates";
import { Link } from "react-router-dom";
import paths from "../../paths";

const MeetingAttributesEdit = () => {
  const meeting = meetings[0];
  return (
    <div className="page-container flex-grow-1">
      <div className="px-4 pb-3">
        <Link to={paths.construct.meeting(meeting.id)}>
          <Button kind="secondary">Back to Meeting</Button>
        </Link>
      </div>
      <MeetingHeader title={meeting.title} code="EB2/2024" />
      <div className="d-flex gap-5 flex-grow-1">
        <MeetingInfo meeting={meeting} />
        <div>
          <div className={cn("details-grid", "mb-4")}>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Type</FormLabel>
              <div>
                <Text className="fs-6">Briefing</Text>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Code</FormLabel>
              <div>
                <Text className="fs-6">Briefing12/2024</Text>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Dates</FormLabel>
              <div>
                <div className="d-flex gap-2">
                  <Text className="fs-6">From:</Text>
                  <Text className="fs-6 fw-semibold">10:00 19 Jan 2024</Text>
                </div>
                <div className="d-flex gap-2">
                  <Text className="fs-6">To:</Text>
                  <Text className="fs-6 fw-semibold">15:00 20 Feb 2024</Text>
                </div>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Format</FormLabel>
              <div>
                <Text className="fs-6">Online</Text>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Venue</FormLabel>
              <div>
                <Text className="fs-6">Aula Delegatis - Aula II and III</Text>
              </div>
            </div>
          </div>
          <Text className="fs-4 fw-semibold">Meeting Attributes</Text>
          <div className="d-flex flex-column gap-3 mt-3">
            <div>
              <FormLabel>Registration Status</FormLabel>
              <Select
                className={cn("wfp--react-select-container")}
                classNamePrefix="wfp--react-select"
                placeholder="Registration status"
              />
            </div>

            <div className="d-flex flex-wrap gap-4">
              <div className="flex-grow-0">
                <SingleDatePickerInput
                  datePicker={SingleDatePicker}
                  labelText="Registration opening date"
                  placeholder="From date"
                  inputIconPosition="after"
                  showDefaultInputIcon
                />
              </div>
              <div className="flex-grow-0">
                <SingleDatePickerInput
                  datePicker={SingleDatePicker}
                  labelText="Registration closing date"
                  placeholder="From date"
                  inputIconPosition="after"
                  showDefaultInputIcon
                />
              </div>
            </div>
            <div className="mt-3">
              <Link to={paths.construct.meeting(meeting.id)}>
                <Button>Save Meeting Attributes</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingAttributesEdit;
