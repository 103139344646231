import React, { useMemo } from "react";
import MeetingHeader from "../../components/Meetings/MeetingHeader";
import { meetings, occasionalAttendees, wfpAttendees } from "../../data";
import { FormLabel, Text } from "@wfp/ui";
import { usePagination, useTable } from "react-table";
import Table from "../../components/Table/Table";
import { reportAttendeesColumns } from "../AttendeesReport/AttendeesReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import paths from "../../paths";
import cn from "classnames";
import styles from "../MeetingAttendeesEdit/styles.module.scss";

// TODO add meeting dates | add all filtered entries on meeting attendees table | confirmation button before adding attendees
const Meeting = () => {
  const meeting = meetings[0];

  const columns = useMemo(() => reportAttendeesColumns, []);
  const data = useMemo(() => [...wfpAttendees, ...occasionalAttendees], []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    usePagination
  );

  return (
    <div className="page-container">
      <MeetingHeader title={meeting.title} code="EB2/2024" />
      <div className="d-flex flex-grow-1 gap-4">
        <div className="flex-grow-1 px-4">
          <div className="d-flex align-items-center gap-3 mb-3">
            <Text className="fs-4 fw-semibold">Meeting Attributes</Text>
            <Link
              className="wfp--link"
              to={paths.construct.meetingAttributes(meeting.id)}
            >
              <FontAwesomeIcon
                className="pointer"
                color="#007dbc"
                icon={faEdit}
                size="lg"
              />
            </Link>
          </div>
          <div className={cn("details-grid", "mb-4", "w-50")}>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Type</FormLabel>
              <div>
                <Text className="fs-6">Briefing</Text>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Code</FormLabel>
              <div>
                <Text className="fs-6">Briefing12/2024</Text>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Dates</FormLabel>
              <div>
                <div className="d-flex gap-2">
                  <Text className="fs-6">From:</Text>
                  <Text className="fs-6 fw-semibold">10:00 19 Jan 2024</Text>
                </div>
                <div className="d-flex gap-2">
                  <Text className="fs-6">To:</Text>
                  <Text className="fs-6 fw-semibold">15:00 20 Feb 2024</Text>
                </div>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Format</FormLabel>
              <div>
                <Text className="fs-6">Online</Text>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Venue</FormLabel>
              <div>
                <Text className="fs-6">Aula Delegatis - Aula II and III</Text>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">
                Registration Dates
              </FormLabel>
              <div>
                <div className="d-flex gap-2">
                  <Text className="fs-6">From:</Text>
                  <Text className="fs-6 fw-semibold">19 Jan 2024</Text>
                </div>
                <div className="d-flex gap-2">
                  <Text className="fs-6">To:</Text>
                  <Text className="fs-6 fw-semibold">20 Feb 2024</Text>
                </div>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">
                Registration Status
              </FormLabel>
              <Text className="fs-6">open</Text>
            </div>
          </div>
          <div className="w-100 mt-4">
            <div className="d-flex align-items-center gap-3 mb-3">
              <Text className="fs-4 fw-semibold">Meeting Attendees</Text>
              <Link
                className="wfp--link"
                to={paths.construct.meetingAttendees(meeting.id)}
              >
                <FontAwesomeIcon
                  className="pointer"
                  color="#007dbc"
                  icon={faEdit}
                  size="lg"
                />
              </Link>
            </div>{" "}
            <Table
              tableInstance={tableInstance}
              emptyTitle="No attendees selected"
              paginationProps={{
                pageSizesDisabled: true,
                page: 1,
              }}
              emptyMessage=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meeting;
