import React from "react";
import { Text } from "@wfp/ui";

type Props = {
  title: string;
  code: string;
};

const MeetingHeader = ({ title, code }: Props) => (
  <div className="mx-lg-4 mx-3 mr-0 mb-3">
    <Text className="fs-2 fw-semibold mb-2">{title}</Text>
    <Text className="fs-5 mb-2">{code}</Text>
  </div>
);

export default MeetingHeader;
