const paths = {
  match: {
    index: "/",
    meeting: "/meeting/:id",
    meetingReport: "/meeting/:id/report",
    meetingAttendees: "/meeting/:id/attendees",
    meetingAttributes: "/meeting/:id/attributes",
  },
  construct: {
    index: () => "/",
    meeting: (id: number | string) => `/meeting/${id}`,
    meetingAttendees: (id: number | string) => `/meeting/${id}/attendees`,
    meetingReport: (id: number | string) => `/meeting/${id}/report`,
    meetingAttributes: (id: number | string) => `/meeting/${id}/attributes`,
  },
};

export default paths;
