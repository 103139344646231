import React from "react";
import MeetingsFilter from "./MeetingsFilter";
import MeetingsTable from "./MeetingsTable";
import { Text } from "@wfp/ui";

const Home = () => {
  return (
    <div className="page-container px-4">
      <Text className="fs-2 fw-semibold mb-2">Upcoming Meetings</Text>
      <div className="mb-5">
        <MeetingsFilter />
      </div>
      <MeetingsTable />
    </div>
  );
};

export default Home;
