import React, { useState } from "react";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup, Modal, RadioButton, Text } from "@wfp/ui";
import { occasionalAttendees } from "../../data";
import AttendanceFormatSelect from "../../components/Attendees/AttendanceFormatSelect/AttendanceFormatSelect";
import SpecialAccommodationsField from "../../components/Attendees/SpecialAccommodationsField/SpecialAccommodationsField";

type Props = {
  attendeeId: number;
};

const ManageRegistrationInfoModal = ({ attendeeId }: Props) => {
  const [show, setShow] = useState(false);
  const hideModal = () => setShow(false);
  const showModal = () => setShow(true);
  const attendee = occasionalAttendees.find(({ id }) => id === attendeeId);
  return (
    <div>
      <FontAwesomeIcon
        className="pointer"
        size="lg"
        title="Manage Registration Info"
        color="#0087cb"
        icon={faPenToSquare}
        onClick={showModal}
      />
      {!!attendee && (
        <Modal
          open={show}
          onRequestClose={hideModal}
          modalHeading="Manage Registration Info"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          onSecondarySubmit={hideModal}
          onRequestSubmit={hideModal}
        >
          <Text className="h5">{attendee.name}</Text>
          <div className="d-flex flex-column mt-4 gap-3">
            <AttendanceFormatSelect selectId="manage-registration-info" />
            <SpecialAccommodationsField fieldId="manage-registration-info" />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ManageRegistrationInfoModal;
