import React, { useState } from "react";
import { FormLabel, RadioButton, TextInput } from "@wfp/ui";

type Props = {
  fieldId: string;
};

const SpecialAccommodationsField = ({ fieldId }: Props) => {
  const [specialAccommodationsValue, setSpecialAccommodationsValue] =
    useState(false);

  return (
    <div>
      <div>
        <FormLabel>Special accommodations</FormLabel>
        <div className="d-flex gap-4">
          <RadioButton
            labelText="Yes"
            value="1"
            name={`${fieldId}-attendee-disability`}
            onChange={(e) => setSpecialAccommodationsValue(!!e.target.value)}
          />
          <RadioButton
            labelText="No"
            value=""
            onChange={(e) => setSpecialAccommodationsValue(!!e.target.value)}
            name={`${fieldId}-attendee-disability`}
          />
        </div>
      </div>
      {specialAccommodationsValue && (
        <div className="mt-2 d-flex flex-column gap-2">
          <TextInput labelText="Accessibility needs" />
          <TextInput labelText="Special assistance requirements" />
        </div>
      )}
    </div>
  );
};

export default SpecialAccommodationsField;
