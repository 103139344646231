import styles from "./styles.module.scss";
import React from "react";
import { Table, PaginationProps, TablePagination } from "@wfp/ui";
import { TableInstance } from "react-table";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

function ReactTable<T extends Record<any, any>>(props: {
  tableInstance: TableInstance<T>;
  emptyTitle: string;
  emptyMessage: string;
  fluid?: boolean;
  loading?: boolean;
  paginationProps?: Partial<PaginationProps>;
}) {
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    props.tableInstance;

  const pagProps = {
    ...(props.tableInstance as any),
    onPageSizeChange: (page: number) => {},
    itemsPerPageText: "",
    itemsPerPageFollowsText: "",
    onPageChange: (page: number) => {
      props.tableInstance.gotoPage(page);
    },
  };

  const onPageSizeChange = (pageSize: number) => {};

  if (!page.length) return null;

  return (
    <div
      className={cn({
        [styles.tableWrapper]: true,
        [styles.tableFluid]: props.fluid ?? false,
      })}
    >
      <Table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const columnProps = {
                  ...column.getHeaderProps({
                    ...(column.canSort ? column.getSortByToggleProps() : {}),
                    style: { minWidth: column.minWidth, width: column.width },
                  }),
                };
                return (
                  <th {...columnProps} title="">
                    <div
                      style={{
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        justifyContent: column.center ? "center" : "flex-start",
                        cursor: column.canSort ? "pointer" : "initial",
                      }}
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon
                            icon={faCaretDown as IconProp}
                            style={{ marginLeft: 7 }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCaretDown as IconProp}
                            style={{
                              transform: `rotate(${180}deg)`,
                              marginLeft: 7,
                            }}
                          />
                        )
                      ) : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr
                onClick={
                  row.original.onClick ? row.original.onClick : function () {}
                }
                {...row.getRowProps()}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                          maxWidth: cell.column.maxWidth,
                          whiteSpace: cell.column.whiteSpace,
                        },
                        ...(cell.column.className
                          ? { className: cell.column.className }
                          : {}),
                      })}
                      data-column={cell.column.id}
                    >
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      <div
                        style={{
                          justifyContent: cell.column.center
                            ? "center"
                            : "flex-start",
                        }}
                      >
                        {cell.render("Cell")}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="fspace" />
      <TablePagination {...pagProps} {...props.paginationProps} />
    </div>
  );
}

export default ReactTable;
