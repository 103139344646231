import moment from "moment";
import React, { useMemo } from "react";
import { Column, usePagination, useTable } from "react-table";
import Table from "../../components/Table/Table";
import {
  Meeting,
  MeetingFormat,
  meetingFormatLabels,
  meetings,
} from "../../data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import paths from "../../paths";

const MeetingsTable = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Meeting Title",
        accessor: "title",
        className: "fw-semibold",
      },
      {
        Header: "Start date",
        Cell: ({ cell }: any) => {
          const meeting = cell.row.original as Meeting;
          return (
            <div>
              {moment(meeting.start_date).format("DD/MM/YYYY")}{" "}
              {meeting.start_time}
            </div>
          );
        },
      },
      {
        Header: "End date",
        Cell: ({ cell }: any) => {
          const meeting = cell.row.original as Meeting;
          return (
            <div>
              {moment(meeting.end_date).format("DD/MM/YYYY")} {meeting.end_time}
            </div>
          );
        },
      },
      {
        Header: "Meeting Type",
        accessor: "meeting_type",
      },
      {
        Header: "Meeting Format",
        accessor: "meeting_format",
        Cell: ({ cell }: any) =>
          cell.value ? meetingFormatLabels[cell.value as MeetingFormat] : null,
      },
      {
        Header: "Venue",
        accessor: "venue",
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ cell }: any) => {
          return (
            <div className="d-flex gap-3">
              <Link to={paths.construct.meetingAttributes(cell.value)}>
                <FontAwesomeIcon
                  title="Set Meeting Attributes"
                  className="pointer"
                  color="#0087cb"
                  icon={faPenToSquare}
                />
              </Link>
              <Link to={paths.construct.meetingAttendees(cell.value)}>
                <FontAwesomeIcon
                  title="Manage Attendance List"
                  className="pointer"
                  color="#0087cb"
                  icon={faUsers}
                />
              </Link>
              <Link to={paths.construct.meeting(cell.value)}>
                <FontAwesomeIcon
                  title="Manage Attendance List"
                  className="pointer"
                  color="#0087cb"
                  icon={faEye}
                />
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => meetings, []);

  const tableInstance = useTable(
    {
      columns: columns as Column<Meeting>[],
      data,
    },
    usePagination
  );

  return (
    <Table
      tableInstance={tableInstance}
      emptyTitle="No Meetings"
      paginationProps={{
        pageSizesDisabled: true,
        page: 1,
      }}
      emptyMessage="No EditMeeting uploaded"
    />
  );
};

export default MeetingsTable;
