import React from "react";
import { Meeting } from "../../../data";
import styles from "./styles.module.scss";
import cn from "classnames";

type Props = {
  meeting: Meeting;
};

const MeetingInfo = ({ meeting }: Props) => {
  return (
    <div className={styles.meetingInfo + " px-lg-4 px-3 mr-0"}>
      <div className={cn("d-flex gap-2", styles.meetingInfoRow)}>
        <div className="fw-bold">Current status: </div>
        Published
      </div>
      <div className={cn("d-flex gap-2", styles.meetingInfoRow)}>
        <div className="fw-bold">Created: </div>
        03/11/2023
      </div>
      <div className={cn("d-flex gap-2", styles.meetingInfoRow)}>
        <div className="fw-bold">First time published:</div>
        03/11/2023
      </div>
      <div className={cn("d-flex gap-2", styles.meetingInfoRow)}>
        <div className="fw-bold">Last updated: </div>
        30/01/2024
      </div>
    </div>
  );
};

export default MeetingInfo;
