import React, { useMemo } from "react";
import MeetingHeader from "../../components/Meetings/MeetingHeader";
import {
  AttendanceFormat,
  attendanceFormatLabels,
  Attendee,
  AttendeeType,
  attendeeTypes,
  meetings,
  occasionalAttendees,
  RegistrationType,
  registrationTypeLabels,
  wfpAttendees,
} from "../../data";
import { Column, usePagination, useTable } from "react-table";
import ReactTable from "../../components/Table/Table";
import cn from "classnames";
import Select from "react-select";
import { Button, Checkbox, FormLabel } from "@wfp/ui";
import { Link } from "react-router-dom";
import paths from "../../paths";

export const reportAttendeesColumns = [
  {
    Header: "Attendee type",
    accessor: "attendee_type",
    Cell: ({ cell }: any) =>
      cell.value ? attendeeTypes[cell.value as AttendeeType] : "",
  },

  { Header: "Division", accessor: "department" },
  {
    Header: "Organization",
    accessor: "organization",
    maxWidth: 300,
    width: 270,
    minWidth: 120,
    whiteSpace: "wrap",
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    Header: "Organization EB status",
    accessor: "eb_status",
  },
  {
    Header: "Protocol title",
    accessor: "protocol_title",
  },
  {
    Header: "Full name",
    accessor: "name",
  },
  { Header: "Rank", accessor: "rank" },

  {
    Header: "Official title",
    accessor: "title",
  },
  {
    Header: "Registration type",
    accessor: "registration_type",
    Cell: ({ cell }: any) => {
      return registrationTypeLabels[cell.value as RegistrationType];
    },
  },
  {
    Header: "Attendance format",
    accessor: "attendance_format",
    Cell: ({ cell }: any) => {
      return attendanceFormatLabels[cell.value as AttendanceFormat];
    },
  },
  {
    Header: "Special accommodations",
    accessor: "special_accommodations",

    Cell: ({ cell }: any) => {
      const id = cell.row.original.id;
      let display = "No";
      if (cell.value) {
        display = typeof cell.value === "boolean" ? "Yes" : cell.value;
      }

      return (
        <div className="d-flex flex-grow-1 justify-content-center">
          {display}
        </div>
      );
    },
  },
] as Column<Attendee>[];

const AttendeesReport = () => {
  const meeting = meetings[0];
  const columns = useMemo(() => reportAttendeesColumns, []);
  const data = useMemo(() => [...wfpAttendees, ...occasionalAttendees], []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    usePagination
  );

  return (
    <div className="page-container flex-grow-1">
      <div className="px-4 pb-3">
        <Link to={paths.construct.meeting(meeting.id)}>
          <Button kind="secondary">Back to Meeting</Button>
        </Link>
      </div>
      <MeetingHeader title={meeting.title} code="EB2/2024" />
      <div className="px-4 mb-4">
        <div className="d-flex flex-wrap column-gap-4 row-gap-3 mb-3">
          <div className="flex-grow-1">
            <FormLabel>Data Source</FormLabel>
            <Select
              className={cn("wfp--react-select-container")}
              classNamePrefix="wfp--react-select"
              placeholder="Nothing selected"
              options={[
                { label: "WFP", value: 1 },
                { label: "Occasional", value: 2 },
                { label: "EBCM Contacts", value: 3 },
              ]}
              value={{ label: "WFP", value: 1 }}
            />
          </div>
          <div className="flex-grow-1">
            <FormLabel>Title</FormLabel>
            <Select
              className={cn("wfp--react-select-container")}
              classNamePrefix="wfp--react-select"
              placeholder="Nothing selected"
              options={[{ label: "Country Director", value: 1 }]}
            />
          </div>
          <div className="flex-grow-1">
            <FormLabel>Division</FormLabel>
            <Select
              className={cn("wfp--react-select-container")}
              classNamePrefix="wfp--react-select"
              placeholder="Nothing selected"
              options={[{ label: "Brazaville", value: 1 }]}
            />
          </div>
          <div className="flex-grow-1">
            <FormLabel>Rank</FormLabel>
            <Select
              className={cn("wfp--react-select-container")}
              classNamePrefix="wfp--react-select"
              placeholder="Nothing selected"
              options={[{ label: "Ambassador", value: 1 }]}
            />
          </div>
        </div>
        <div className="d-flex gap-2">
          <Button kind="secondary">Download PDF</Button>
          <Button>Download CSV</Button>
        </div>
      </div>

      <div className="mx-4">
        <ReactTable
          tableInstance={tableInstance}
          emptyTitle="No attendees added"
          emptyMessage=""
          paginationProps={{
            page: 1,
          }}
        />
      </div>
    </div>
  );
};

export default AttendeesReport;
