export type AttendanceFormat = 1 | 2 | 3;
export const attendanceFormatLabels = {
  1: "On-site",
  2: "Online",
  3: "Hybrid",
};

export type MeetingFormat = AttendanceFormat;
export const meetingFormatLabels = attendanceFormatLabels;

export type Meeting = {
  title: string;
  start_date: string;
  end_date: string;
  meeting_format?: MeetingFormat;
  start_time: string;
  end_time: string;
  venue?: string;
  id: number;
  meeting_type: string;
};

export const meetings = [
  {
    title: "First regular session of the Executive Board",

    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "18:30",
    meeting_type: "Electoral List",
    venue: "Auditorium",
    meeting_format: 1,
    id: 1,
  },
  {
    title: "Annual Session of the Executive Board",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "17:30",
    meeting_type: "Electoral List",
    venue: "Aula Delegatis - Aula II and III",
    meeting_format: 2,
    id: 2,
  },
  {
    title: "Second Regular Session of the Executive Board",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "15:00",
    venue: "Forum",
    meeting_format: 1,
    id: 3,
  },
  {
    title: "Sixth management update on WFP operations in Ethiopia",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "12:30",
    meeting_type: "Executive Board - First Regular Session",
    venue: "Auditorium",
    meeting_format: 1,
    id: 4,
  },
  {
    title: "Orange Campaign",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "18:30",
    meeting_type: "Electoral List",
    venue: "Red Meeting Room (0R00)",
    meeting_format: 3,
    id: 5,
  },
  {
    title:
      "Focus group discussion with B on the mid-term evaluation of the strategic plan 2022-2025",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    meeting_type: "Electoral List",
    end_time: "17:30",
    venue: "Forum",
    meeting_format: 1,
    id: 6,
  },
  {
    title: "Operational briefing on Gaza",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "15:00",
    venue: "Aula Delegatis - Aula II and III",
    meeting_format: 2,
    id: 7,
  },
  {
    title: "Executive Board Bureau meeting",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "12:30",
    meeting_type: "Executive Board - First Regular Session",
    venue: "Seed to Feed Area",
    meeting_format: 2,
    id: 8,
  },
  {
    title: "First regular session of the Executive Board",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "18:30",
    meeting_type: "Electoral List",
    meeting_format: 3,
    venue: "Aula Delegatis - Aula II and III",
    id: 9,
  },
  {
    title: "Briefing on JIU web-based tracking system",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "17:30",
    meeting_type: "Electoral List",
    venue: "Gazebo",
    meeting_format: 2,
    id: 10,
  },
  {
    title: "Second Regular Session of the Executive Board",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "15:00",
    venue: "Aula Delegatis - Aula II and III",
    meeting_format: 2,
    id: 11,
  },
  {
    title: "Sixth management update on WFP operations in Ethiopia",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "12:30",
    meeting_type: "Executive Board - First Regular Session",
    venue: "Rooftop Meeting Room (7th floor)",
    meeting_format: 3,
    id: 12,
  },
  {
    title: "Briefing on the Resilience policy update: annotated outline",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "18:30",
    meeting_type: "Electoral List",
    venue: "Seed to Feed Area",
    meeting_format: 2,
    id: 13,
  },
  {
    title: "Pre EB.1/2024 list E meeting with the Executive Director",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "17:30",
    meeting_type: "Electoral List",
    venue: "Forum",
    meeting_format: 1,
    id: 14,
  },
  {
    title: "Pre EB-1/2024 electoral list D meeting with the Executive Director",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "15:00",
    venue: "Red Meeting Room (0R00)",
    meeting_format: 3,
    id: 15,
  },
  {
    title: "First 2024 Executive Board Bureau meeting",
    start_date: new Date().toDateString(),
    end_date: new Date().toDateString(),
    start_time: "10:00",
    end_time: "12:30",
    meeting_type: "Executive Board - First Regular Session",
    venue: "Forum",
    meeting_format: 1,
    id: 16,
  },
] as Meeting[];

export type DataSource = "wfp" | "occasional" | "ebcm" | "meeting-attendees";
export type AttendeeType = "wfp" | "occasional" | "ebcm";

export type RegistrationType = 1 | 2;
export const registrationTypeLabels = {
  1: "Speaker/Presenter",
  2: "Viewer",
};
export type Attendee = {
  title: string;
  name: string;
  email: string;
  country?: string;
  organization?: string;
  registration_type?: RegistrationType;
  attendance_format?: AttendanceFormat;
  protocol_title?: string;
  eb_status?: string;
  department?: string;
  special_accommodations?: boolean | string;
  rank?: string;
  attendee_type?: AttendeeType;
  id: number;
};

export const attendeeTypes = {
  wfp: "WFP attendee",
  ebcm: "Mission Attendee",
  occasional: " Occasional Attendee",
};

export const wfpAttendees: Attendee[] = [
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "John Doe",
    email: "john.doe@gmail.com",
    registration_type: 1,
    attendance_format: 2,
    protocol_title: "Mr",
    attendee_type: "wfp",
    department: "Brazzaville",
    id: 15,
    organization:
      "Ambassade de la République Algérienne Démocratique et Populaire",
    country: "Algeria",
    special_accommodations: true,
  },
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "Loella Mamie",
    registration_type: 2,
    email: "loella.mamie@mailinator.com",
    attendance_format: 3,
    protocol_title: "Ms",
    attendee_type: "wfp",
    department: "Manchester",
    organization: "AVSI Foundation",
    id: 16,
    country: "Norway",
  },
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "Babara Ginnifer",
    email: "babara.ginnifer@gmail.com",
    attendance_format: 1,
    registration_type: 2,
    protocol_title: "Ms",
    attendee_type: "wfp",
    department: "Brazzaville",
    organization: "Solidarites International",
    country: "Yemen",
    id: 17,
    special_accommodations: false,
  },
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "John Doe",
    email: "john.doe@gmail.com",
    registration_type: 1,
    attendance_format: 2,
    protocol_title: "Mr",
    attendee_type: "wfp",
    department: "Brazzaville",
    organization: "World Vision International",
    country: "Norway",
    id: 18,
    special_accommodations: true,
  },
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "John Doe",
    email: "john.doe@gmail.com",
    registration_type: 1,
    attendance_format: 2,
    protocol_title: "Mr",
    attendee_type: "wfp",
    organization: "Groupe de Recherche Action pour le (Mali)",
    country: "Indonesia",
    department: "Brazzaville",
    id: 19,
  },
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "Loella Mamie",
    registration_type: 2,
    email: "loella.mamie@mailinator.com",
    attendance_format: 3,
    protocol_title: "Ms",
    attendee_type: "wfp",
    department: "Manchester",
    organization: "Solidarites International",
    country: "Yemen",
    special_accommodations: "Wheelchair",
    id: 20,
  },
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "Babara Ginnifer",
    email: "babara.ginnifer@gmail.com",
    attendance_format: 1,
    registration_type: 2,
    protocol_title: "Ms",
    attendee_type: "wfp",
    department: "Brazzaville",
    organization: "World Vision International",
    country: "Norway",
    id: 21,
  },
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "John Doe",
    email: "john.doe@gmail.com",
    registration_type: 1,
    attendance_format: 2,
    protocol_title: "Mr",
    attendee_type: "wfp",
    department: "Brazzaville",
    organization: "Groupe de Recherche Action pour le (Mali)",
    country: "Indonesia",
    id: 22,
  },
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "John Doe",
    email: "john.doe@gmail.com",
    registration_type: 1,
    attendance_format: 2,
    protocol_title: "Mr",
    attendee_type: "wfp",
    department: "Brazzaville",
    organization: "World Vision International",
    country: "Norway",
    special_accommodations: "Wheelchair",
    id: 23,
  },
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "Loella Mamie",
    registration_type: 2,
    email: "loella.mamie@mailinator.com",
    attendance_format: 3,
    protocol_title: "Ms",
    attendee_type: "wfp",
    department: "Manchester",
    organization: "Solidarites International",
    country: "Yemen",
    id: 24,
  },
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "Babara Ginnifer",
    email: "babara.ginnifer@gmail.com",
    attendance_format: 1,
    registration_type: 2,
    protocol_title: "Ms",
    attendee_type: "wfp",
    department: "Brazzaville",
    organization: "Groupe de Recherche Action pour le (Mali)",
    country: "Indonesia",
    id: 25,
  },
  {
    title: "Country Director",
    eb_status: "Member",
    rank: "Ambassador",
    name: "John Doe",
    email: "john.doe@gmail.com",
    registration_type: 1,
    attendance_format: 2,
    protocol_title: "Mr",
    attendee_type: "wfp",
    department: "Brazzaville",
    organization: "World Vision International",
    country: "Norway",
    id: 26,
  },
];

export const occasionalAttendees: Attendee[] = [
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Melvin Vargas",
    email: "melvin.vargas@example.com",
    organization: "Norway",
    rank: "Ambassador",
    id: 1,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Marie Richardson",
    email: "marie.richardson@example.com",
    organization: "Malaysia",
    rank: "",
    id: 2,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Terry Lawson",
    email: "terry.lawson@example.com",
    organization: "Sweden",
    rank: "Ambassador",
    id: 3,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Esther Pierce",
    email: "esther.pierce@example.com",
    organization: "Malaysia",
    rank: "",
    id: 4,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Melvin Vargas",
    email: "melvin.vargas@example.com",
    organization: "Norway",
    rank: "Ambassador",
    id: 5,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Marie Richardson",
    email: "marie.richardson@example.com",
    organization: "Malaysia",
    rank: "",
    id: 6,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Terry Lawson",
    email: "terry.lawson@example.com",
    organization: "Sweden",
    rank: "Ambassador",
    id: 7,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Esther Pierce",
    email: "esther.pierce@example.com",
    organization: "Malaysia",
    rank: "",
    id: 8,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Melvin Vargas",
    email: "melvin.vargas@example.com",
    organization: "Norway",
    rank: "Ambassador",
    id: 9,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Marie Richardson",
    email: "marie.richardson@example.com",
    organization: "Malaysia",
    rank: "",
    id: 10,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Terry Lawson",
    email: "terry.lawson@example.com",
    organization: "Sweden",
    rank: "Ambassador",
    id: 11,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Esther Pierce",
    email: "esther.pierce@example.com",
    organization: "Malaysia",
    rank: "",
    id: 12,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Melvin Vargas",
    email: "melvin.vargas@example.com",
    organization: "Norway",
    rank: "Ambassador",
    id: 12,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Marie Richardson",
    email: "marie.richardson@example.com",
    organization: "Malaysia",
    rank: "",
    id: 13,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Terry Lawson",
    email: "terry.lawson@example.com",
    organization: "Sweden",
    rank: "Ambassador",
    id: 14,
  },
  {
    attendee_type: "occasional",
    title: "Alternate Permanent",
    name: "Esther Pierce",
    email: "esther.pierce@example.com",
    organization: "Malaysia",
    rank: "",
    id: 15,
  },
];

export const missionAttendees: Attendee[] = [
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Melvin Vargas",
    email: "melvin.vargas@example.com",
    organization: "Norway",
    rank: "Ambassador",
    id: 1,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Marie Richardson",
    email: "marie.richardson@example.com",
    organization: "Malaysia",
    rank: "",
    id: 2,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Terry Lawson",
    email: "terry.lawson@example.com",
    organization: "Sweden",
    rank: "Ambassador",
    id: 3,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Esther Pierce",
    email: "esther.pierce@example.com",
    organization: "Malaysia",
    rank: "",
    id: 4,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Melvin Vargas",
    email: "melvin.vargas@example.com",
    organization: "Norway",
    rank: "Ambassador",
    id: 5,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Marie Richardson",
    email: "marie.richardson@example.com",
    organization: "Malaysia",
    rank: "",
    id: 6,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Terry Lawson",
    email: "terry.lawson@example.com",
    organization: "Sweden",
    rank: "Ambassador",
    id: 7,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Esther Pierce",
    email: "esther.pierce@example.com",
    organization: "Malaysia",
    rank: "",
    id: 8,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Melvin Vargas",
    email: "melvin.vargas@example.com",
    organization: "Norway",
    rank: "Ambassador",
    id: 9,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Marie Richardson",
    email: "marie.richardson@example.com",
    organization: "Malaysia",
    rank: "",
    id: 10,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Terry Lawson",
    email: "terry.lawson@example.com",
    organization: "Sweden",
    rank: "Ambassador",
    id: 11,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Esther Pierce",
    email: "esther.pierce@example.com",
    organization: "Malaysia",
    rank: "",
    id: 12,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Melvin Vargas",
    email: "melvin.vargas@example.com",
    organization: "Norway",
    rank: "Ambassador",
    id: 12,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Marie Richardson",
    email: "marie.richardson@example.com",
    organization: "Malaysia",
    rank: "",
    id: 13,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Terry Lawson",
    email: "terry.lawson@example.com",
    organization: "Sweden",
    rank: "Ambassador",
    id: 14,
  },
  {
    attendee_type: "ebcm",
    title: "Alternate Permanent",
    name: "Esther Pierce",
    email: "esther.pierce@example.com",
    organization: "Malaysia",
    rank: "",
    id: 15,
  },
];

export type Department = {
  label: string;
  id: number;
};

export const departments: Department[] = [
  {
    label: "Brazzaville",
    id: 1,
  },
  { label: "Manchester", id: 2 },
  { label: "Rome", id: 3 },
];

export type Organization = {
  label: string;
  id: number;
};

export const organizations: Organization[] = [
  {
    label: "Norway",
    id: 1,
  },
  {
    label: "Malaysia",
    id: 2,
  },
  { label: "Sweden", id: 3 },
];
