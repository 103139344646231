import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Checkbox, Modal } from "@wfp/ui";
import { reportAttendeesColumns } from "../AttendeesReport/AttendeesReport";
import { Attendee } from "../../data";
import { Column, usePagination, useTable } from "react-table";
import Table from "../../components/Table/Table";

type Props = {
  triggerModalButton: (onClick: () => void) => ReactNode;
  attendeesToAdd: Attendee[];
  setAttendeesToAdd: React.Dispatch<React.SetStateAction<Attendee[]>>;
};

const AttendeesToAddModal = ({
  triggerModalButton,
  attendeesToAdd,
  setAttendeesToAdd,
}: Props) => {
  const [show, setShow] = useState(false);
  const hideModal = () => setShow(false);

  const [attendeesEdited, setAttendeesEdited] = useState(attendeesToAdd);
  const attendeesEditedIds = useMemo(
    () => attendeesEdited.map(({ id }) => id),
    [attendeesEdited]
  );

  const saveChanges = (callback: () => any) => () => {
    setAttendeesToAdd(attendeesEdited);
    callback();
  };

  const toggleAttendee = (id: number) => {
    const attendee = attendeesToAdd.find((item) => +item.id === +id);
    if (attendee) {
      setAttendeesEdited((prev) =>
        attendeesEditedIds.includes(id)
          ? prev.filter((item) => item.id !== id)
          : [...prev, attendee]
      );
    }
  };

  const columns = useMemo(
    () =>
      [
        {
          accessor: "id",
          Cell: ({ cell }) => (
            <div style={{ height: 10 }}>
              <Checkbox
                id={`attendee-to-add-checkbox-${cell.value}`}
                checked={attendeesEditedIds.includes(cell.value)}
                onClick={() => toggleAttendee(cell.value)}
              />
            </div>
          ),
          Header: "",
        },
        ...reportAttendeesColumns,
      ] as Column<Attendee>[],
    [attendeesEdited, attendeesToAdd, attendeesEditedIds]
  );
  const data = useMemo(() => attendeesToAdd, [attendeesToAdd]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    usePagination
  );

  useEffect(() => {
    setAttendeesEdited(attendeesToAdd);
  }, [attendeesToAdd]);

  return (
    <>
      {triggerModalButton(() => setShow(true))}
      <Modal
        open={show}
        wide
        modalHeading="Attendees Selected"
        primaryButtonText="Save Changes"
        secondaryButtonText="Reset"
        onRequestSubmit={saveChanges(hideModal)}
        onRequestClose={hideModal}
        onSecondarySubmit={hideModal}
      >
        <Table
          tableInstance={tableInstance}
          emptyTitle=""
          emptyMessage=""
          paginationProps={{
            page: 1,
          }}
        />
      </Modal>
    </>
  );
};

export default AttendeesToAddModal;
