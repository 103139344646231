import React, { ReactNode, useState } from "react";
import { FormLabel, Modal, RadioButton, TextInput } from "@wfp/ui";
import Select from "react-select";
import { Attendee } from "../../data";
import AttendanceFormatSelect from "../../components/Attendees/AttendanceFormatSelect/AttendanceFormatSelect";
import SpecialAccommodationsField from "../../components/Attendees/SpecialAccommodationsField/SpecialAccommodationsField";
type Props = {
  triggerModalButton: (onClick: () => void) => ReactNode;
};

const AddAttendeeModal = ({ triggerModalButton }: Props) => {
  const [show, setShow] = useState(false);
  const hideModal = () => setShow(false);

  const [specialAccommodationsValue, setSpecialAccommodationsValue] =
    useState(false);

  return (
    <div>
      {triggerModalButton(() => setShow(true))}
      <Modal
        primaryButtonText="Add"
        secondaryButtonText="Cancel"
        open={show}
        modalHeading="Add New Occasional Attendee"
        onRequestClose={hideModal}
        onSecondarySubmit={hideModal}
      >
        <div className="d-flex flex-column gap-3">
          <div className="d-flex gap-3">
            <div className="flex-grow-1">
              <TextInput labelText="Protocol title*" />
            </div>
            <div className="flex-grow-1" style={{ marginTop: -2 }}>
              <FormLabel>Type</FormLabel>
              <Select
                placeholder="Nothing selected"
                options={[
                  { label: "Attendee", value: 1 },
                  { label: "Viewer", value: 2 },
                ]}
                className="wfp--react-select-container"
                classNamePrefix="wfp--react-select"
              />
            </div>
          </div>
          <div className="d-flex gap-3">
            <TextInput labelText="First name*" />
            <TextInput labelText="Middle name" />
            <TextInput labelText="Last name*" />
          </div>
          <div className="d-flex gap-3">
            <TextInput labelText="Rank" />
            <TextInput labelText="Official title*" />
          </div>
          <div className="d-flex gap-3">
            <TextInput labelText="Phone number" />
            <TextInput labelText="Email address*" />
          </div>
          <div className="d-flex gap-3">
            <TextInput labelText="Mission or Organization" />
            <TextInput labelText="Organization name" />
            <TextInput labelText="Country" />
          </div>
          <div className="d-flex gap-3">
            <TextInput labelText="Organization EB status*" />
            <TextInput labelText="Organization type*" />
          </div>
          <div className="d-flex">
            <div className="flex-grow-1">
              <FormLabel>Registration type*</FormLabel>
              <Select
                placeholder="Nothing selected"
                options={[
                  { label: "Speaker/Presenter", value: 1 },
                  { label: "Viewer", value: 2 },
                ]}
                defaultValue={{ label: "Speaker/Presenter", value: 1 }}
                className="wfp--react-select-container"
                classNamePrefix="wfp--react-select"
              />
            </div>
          </div>
          <AttendanceFormatSelect selectId="add-attendee" />
          <SpecialAccommodationsField fieldId="add-attendee" />
        </div>
      </Modal>
    </div>
  );
};

export default AddAttendeeModal;
