import React, { useContext, useState } from "react";
import { Button, FormLabel, Modal, Text } from "@wfp/ui";
import Select from "react-select";
import {
  Attendee,
  DataSource,
  departments,
  meetings,
  organizations,
} from "../../data";
import cn from "classnames";
import styles from "./styles.module.scss";
import AddAttendeeModal from "./AddAttendeeModal";
import { Link } from "react-router-dom";
import paths from "../../paths";
import AttendeesToAddModal from "./AttendeesToAddModal";
import MeetingHeader from "../../components/Meetings/MeetingHeader";
import MeetingAttendeesTable from "./MeetingAttendeesTable";
import { ToastNotificationContext } from "../../components/ToastNotificationContext";

// @TODO Add third attendees data source and table with list of added attendees

const dataSources: { [key: string]: { label: string; value: DataSource } } = {
  "meeting-attendees": {
    label: "All meeting attendees",
    value: "meeting-attendees",
  },
  wfp: { label: "WFP attendees", value: "wfp" },
  occasional: { label: "Occasional attendees", value: "occasional" },
  ebcm: { label: "Mission attendees", value: "ebcm" },
};

type Props = {};

const MeetingAttendeesEdit = (props: Props) => {
  const [attendeesToAdd, setAttendeesToAdd] = useState<Attendee[]>([]);
  const [allAttendeesChecked, setAllAttendeesChecked] =
    useState<boolean>(false);
  const [dataSource, setDataSource] = useState<DataSource>("meeting-attendees");
  const { showNotificationWithTimeout, setNotificationData } = useContext(
    ToastNotificationContext
  );
  const meeting = meetings[0];

  const clearAttendees = () => {
    setAttendeesToAdd([]);
    setAllAttendeesChecked(false);
  };

  const submitAttendees = () => {
    setNotificationData({
      kind: "success",
      title: "Attendees were successfully added",
      role: "",
    });
    showNotificationWithTimeout();
    clearAttendees();
  };

  return (
    <div className="page-container flex-grow-1">
      <div className="px-4 pb-3">
        <Link to={paths.construct.meeting(meeting.id)}>
          <Button kind="secondary">Back to Meeting</Button>
        </Link>
      </div>
      <MeetingHeader title={meeting.title} code="EB2/2024" />
      <div className="d-flex gap-5 px-4">
        <div className={styles.meetingForm}>
          <div className={cn("details-grid", "mb-4", "w-50")}>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Type</FormLabel>
              <div>
                <Text className="fs-6">Briefing</Text>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Code</FormLabel>
              <div>
                <Text className="fs-6">Briefing12/2024</Text>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Dates</FormLabel>
              <div>
                <div className="d-flex gap-2">
                  <Text className="fs-6">From:</Text>
                  <Text className="fs-6 fw-semibold">10:00 19 Jan 2024</Text>
                </div>
                <div className="d-flex gap-2">
                  <Text className="fs-6">To:</Text>
                  <Text className="fs-6 fw-semibold">15:00 20 Feb 2024</Text>
                </div>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Meeting Format</FormLabel>
              <div>
                <Text className="fs-6">Online</Text>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">Venue</FormLabel>
              <div>
                <Text className="fs-6">Aula Delegatis - Aula II and III</Text>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">
                Registration Dates
              </FormLabel>
              <div>
                <div className="d-flex gap-2">
                  <Text className="fs-6">From:</Text>
                  <Text className="fs-6 fw-semibold">19 Jan 2024</Text>
                </div>
                <div className="d-flex gap-2">
                  <Text className="fs-6">To:</Text>
                  <Text className="fs-6 fw-semibold">20 Feb 2024</Text>
                </div>
              </div>
            </div>
            <div>
              <FormLabel className="fw-semibold fs-6">
                Registration Status
              </FormLabel>
              <Text className="fs-6">open</Text>
            </div>
          </div>
          <div className="flex-grow-1 mt-3">
            <Text className="fs-4 fw-semibold">Meeting Attendees</Text>
            <div className="mt-2 d-flex justify-content-between column-gap-5">
              <div className="flex-grow-1">
                <FormLabel>Attendee type</FormLabel>
                <Select
                  className={cn("wfp--react-select-container")}
                  classNamePrefix="wfp--react-select"
                  placeholder="Nothing selected"
                  options={Object.values(dataSources)}
                  onChange={(newValue) => {
                    if (newValue?.value) {
                      setDataSource(newValue.value);
                    }
                  }}
                  value={{
                    label: dataSources[dataSource].label,
                    value: dataSource,
                  }}
                />
              </div>
              <div className="flex-grow-1">
                {dataSource === "wfp" ? (
                  <>
                    <FormLabel>Division</FormLabel>
                    <Select
                      className={cn("wfp--react-select-container")}
                      classNamePrefix="wfp--react-select"
                      placeholder="Nothing selected"
                      options={departments.map(({ label, id }) => ({
                        label,
                        value: id,
                      }))}
                      value={null}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <FormLabel>Organization</FormLabel>
                    <Select
                      className={cn("wfp--react-select-container")}
                      classNamePrefix="wfp--react-select"
                      placeholder="Nothing selected"
                      options={organizations.map(({ label, id }) => ({
                        label,
                        value: id,
                      }))}
                      value={null}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="d-flex mt-3 mb-3 justify-content-between align-items-end">
              {!!attendeesToAdd?.length && (
                <div>
                  <Text className="wfp--label">
                    <AttendeesToAddModal
                      attendeesToAdd={attendeesToAdd}
                      setAttendeesToAdd={setAttendeesToAdd}
                      triggerModalButton={(onClick) => (
                        <span onClick={onClick} className="wfp--link">
                          {attendeesToAdd.length}{" "}
                          {attendeesToAdd.length > 1 ? "attendees" : "attendee"}
                        </span>
                      )}
                    />{" "}
                    selected. Do you want to add them?
                  </Text>
                  <div className="d-flex gap-3">
                    <Button onClick={submitAttendees}>Add Attendees</Button>
                    <Button kind="ghost" onClick={clearAttendees}>
                      Clear
                    </Button>
                  </div>
                  <Modal modalHeading="Attendees Selected"></Modal>
                </div>
              )}

              <div className="d-flex gap-3">
                <Link to={paths.construct.meetingReport(1)}>
                  <Button kind="secondary">Generate Report</Button>
                </Link>

                <AddAttendeeModal
                  triggerModalButton={(onClick) => (
                    <Button onClick={onClick}>
                      Add New Occasional Attendee
                    </Button>
                  )}
                />
              </div>
            </div>
            <div className="w-100">
              <MeetingAttendeesTable
                attendeesToAdd={attendeesToAdd}
                setAttendeesToAdd={setAttendeesToAdd}
                allAttendeesChecked={allAttendeesChecked}
                setAllAttendeesChecked={setAllAttendeesChecked}
                dataSource={dataSource}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingAttendeesEdit;
