import React, { useCallback, useMemo, useState } from "react";
import {
  attendanceFormatLabels,
  Attendee,
  attendeeTypes,
  DataSource,
  missionAttendees,
  occasionalAttendees,
  registrationTypeLabels,
  wfpAttendees,
} from "../../data";
import { Checkbox } from "@wfp/ui";
import { Column, usePagination, useTable } from "react-table";
import Table from "../../components/Table/Table";
import Select from "react-select";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ManageRegistrationInfoModal from "./ManageRegistrationInfoModal";

type Props = {
  attendeesToAdd: Attendee[];
  setAttendeesToAdd: React.Dispatch<React.SetStateAction<Attendee[]>>;
  allAttendeesChecked: boolean;
  setAllAttendeesChecked: React.Dispatch<React.SetStateAction<boolean>>;
  dataSource: DataSource;
};

const MeetingAttendeesTable = ({
  attendeesToAdd,
  setAttendeesToAdd,
  allAttendeesChecked,
  setAllAttendeesChecked,
  dataSource,
}: Props) => {
  const attendeesEditedIds = useMemo(
    () => attendeesToAdd.map(({ id }) => id),
    [attendeesToAdd]
  );

  const data = useMemo(() => {
    const map = {
      wfp: wfpAttendees,
      ebcm: missionAttendees,
      occasional: occasionalAttendees,
      "meeting-attendees": [
        ...wfpAttendees,
        ...missionAttendees,
        ...occasionalAttendees,
      ],
    };

    return map[dataSource];
  }, [dataSource]);

  const onSelectAllAttendees = useCallback(() => {
    if (allAttendeesChecked) {
      setAttendeesToAdd([]);
      setAllAttendeesChecked(false);
    } else {
      setAttendeesToAdd(data);
      setAllAttendeesChecked(true);
    }
  }, [data, allAttendeesChecked]);

  const toggleAttendee = (id: number) => {
    const attendee = data.find((item) => item.id === id);
    if (attendee) {
      setAttendeesToAdd((prev) =>
        attendeesEditedIds.includes(id)
          ? prev.filter((item) => item.id !== id)
          : [...prev, attendee]
      );
    }
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: (
            <div style={{ height: 10 }}>
              <Checkbox
                title="Select all"
                id={`attendee-checkbox-add-all`}
                checked={allAttendeesChecked}
                onClick={onSelectAllAttendees}
              />
            </div>
          ),
          id: "attendee-checkbox",
          accessor: "id",
          width: 20,
          Cell: ({ cell }: any) => {
            return (
              <div style={{ height: 10 }}>
                <Checkbox
                  id={`attendee-checkbox-${cell.value}`}
                  checked={attendeesEditedIds.includes(cell.value)}
                  onClick={() => toggleAttendee(cell.value)}
                />
              </div>
            );
          },
        },
        {
          Header: "Attendee type",
          accessor: "attendee_type",
          Cell: ({ cell }) => (cell.value ? attendeeTypes[cell.value] : ""),
        },
        dataSource === "wfp"
          ? { Header: "Division", accessor: "department" }
          : {
              Header: "Organization",
              accessor: "organization",
              maxWidth: 300,
              width: 270,
              minWidth: 120,
              whiteSpace: "wrap",
            },
        {
          Header: "Country",
          accessor: "country",
        },
        {
          Header: "Organization EB status",
          accessor: "eb_status",
        },
        {
          Header: "Protocol title",
          accessor: "protocol_title",
        },
        {
          Header: "Full name",
          accessor: "name",
        },
        { Header: "Rank", accessor: "rank" },

        {
          Header: "Official title",
          accessor: "title",
        },
        {
          Header: "Registration type",
          accessor: "registration_type",
          minWidth: 195,
          Cell: ({ cell }) => {
            return (
              <Select
                className={cn("wfp--react-select-container", "select--sm")}
                classNamePrefix="wfp--react-select"
                options={[
                  { label: "Speaker/Presenter", value: 1 },
                  { label: "Viewer", value: 2 },
                ]}
                defaultValue={{
                  label: cell.value ? registrationTypeLabels[cell.value] : null,
                  value: cell.value,
                }}
              />
            );
          },
        },
        {
          Header: "Attendance format",
          accessor: "attendance_format",
          Cell: ({ cell }) => {
            return (
              <Select
                className={cn("wfp--react-select-container", "select--sm")}
                classNamePrefix="wfp--react-select"
                options={[
                  { label: attendanceFormatLabels[1], value: 1 },
                  { label: attendanceFormatLabels[2], value: 2 },
                  { label: attendanceFormatLabels[3], value: 3 },
                ]}
                defaultValue={{
                  label: cell.value ? attendanceFormatLabels[cell.value] : null,
                  value: cell.value,
                }}
              />
            );
          },
        },
        {
          Header: "Special accommodations",
          accessor: "special_accommodations",

          Cell: ({ cell }: any) => {
            const id = cell.row.original.id;
            return (
              <div className="d-flex flex-grow-1 justify-content-center">
                <div style={{ transform: "translateY(-3px)" }}>
                  <Checkbox
                    defaultChecked={!!cell.value}
                    id={`${id}-special-accommodations-checkbox`}
                  />
                </div>
              </div>
            );
          },
        },
        {
          Header: "",
          accessor: "id",
          Cell: ({ cell }: any) => {
            return (
              <div>
                <ManageRegistrationInfoModal attendeeId={cell.value} />
              </div>
            );
          },
        },
      ] as Column<Attendee>[],
    [attendeesToAdd, onSelectAllAttendees, dataSource]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    usePagination
  );
  return (
    <div className="w-100">
      <Table
        tableInstance={tableInstance}
        emptyTitle="No attendees selected"
        paginationProps={{
          pageSizesDisabled: true,
          page: 1,
        }}
        emptyMessage=""
      />
    </div>
  );
};

export default MeetingAttendeesTable;
