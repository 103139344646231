import { MainNavigation } from "@wfp/ui";
import React from "react";
import { Link } from "react-router-dom";
import paths from "../paths";

export const TopNav = () => {
  return (
    <MainNavigation
      pageWidth="full"
      logo={
        <Link to={paths.construct.index()}>
          Executive Board Attendance Management
        </Link>
      }
    >
      <div />
    </MainNavigation>
  );
};
