import React from "react";
import styles from "./styles.module.scss";
import { Button, FormLabel, SingleDatePickerInput, TextInput } from "@wfp/ui";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import Select from "react-select";

const MeetingsFilter = () => {
  return (
    <div className={styles.filter}>
      <div className={styles.filterInner}>
        <div className={styles.filterTitleField}>
          <TextInput labelText="Meeting Title" placeholder="Title" />
        </div>
        <div style={{ marginTop: -2 }}>
          <FormLabel>Meeting Type</FormLabel>
          <Select
            className="wfp--react-select-container"
            classNamePrefix="wfp--react-select"
            placeholder="Type"
          ></Select>
        </div>
        <TextInput labelText="Meeting Code" placeholder="EB2/XXXX"></TextInput>
        <div className={styles.filterDateField}>
          <SingleDatePickerInput
            datePicker={SingleDatePicker}
            labelText="From date"
            placeholder="From date"
            inputIconPosition="after"
            showDefaultInputIcon
          />
        </div>
        <div className={styles.filterDateField}>
          <SingleDatePickerInput
            datePicker={SingleDatePicker}
            labelText="To date"
            placeholder="To date"
            inputIconPosition="after"
            showDefaultInputIcon
          />
        </div>
      </div>
      <div className={styles.filterActions}>
        <Button kind="ghost">Clear</Button>
        <Button kind="primary">Apply Filters</Button>
      </div>
    </div>
  );
};

export default MeetingsFilter;
