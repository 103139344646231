import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Checkbox, FormLabel, RadioButton, Text } from "@wfp/ui";
import moment from "moment";
import cn from "classnames";

const days = [
  new Date().setDate(6),
  new Date().setDate(7),
  new Date().setDate(8),
  new Date().setDate(9),
  new Date().setDate(10),
  new Date().setDate(11),
  new Date().setDate(12),
  new Date().setDate(13),
  new Date().setDate(14),
  new Date().setDate(15),
  new Date().setDate(16),
  new Date().setDate(17),
  new Date().setDate(18),
  new Date().setDate(19),
  new Date().setDate(20),
  new Date().setDate(21),
  new Date().setDate(22),
];

type Props = {
  selectId: string;
};

const AttendanceFormatSelect = ({ selectId }: Props) => {
  const [selectionType, setSelectionType] = useState<"default" | "daily">(
    days.length > 1 ? "daily" : "default"
  );
  const [attendanceFormatByDay, setAttendanceFormatByDay] = useState<{
    [key: number | string]: 1 | 0;
  }>({});

  const onRadioButtonChange = (day: number | string, value: 1 | 0) => {
    setAttendanceFormatByDay((temp) => ({ ...temp, [day]: value }));
  };

  useEffect(() => {
    const temp: { [key: number]: 1 | 0 } = {};
    days.forEach((day) => {
      temp[day] = 1;
    });
    setAttendanceFormatByDay(temp);
  }, [days]);

  return (
    <div>
      <div className="w-100 d-flex justify-content-between">
        <FormLabel>Attendance Format</FormLabel>
        <div>
          <Checkbox
            id={`${selectId}-attendance-format-select-type`}
            className="mb-0"
            labelText="Pick by date"
            checked={selectionType === "daily"}
            onChange={(e) =>
              setSelectionType(e.target.checked ? "daily" : "default")
            }
          />
        </div>
      </div>
      <div>
        {selectionType === "default" ? (
          <div className="d-flex gap-3">
            <RadioButton
              name={`${selectId}-attendance-format-day`}
              labelText="On site"
              value={1}
              onChange={(e) =>
                onRadioButtonChange("default", +e.target.value as 1)
              }
              checked={!!attendanceFormatByDay["default"]}
              defaultChecked
            />
            <RadioButton
              name={`${selectId}-attendance-format-day`}
              labelText="Online"
              value={0}
              onChange={(e) =>
                onRadioButtonChange("default", +e.target.value as 0)
              }
              checked={!attendanceFormatByDay["default"]}
            />
          </div>
        ) : (
          <div className={styles.select}>
            {days.map((day, i) => (
              <div
                key={`${selectId}-attendance-format-day-${day}`}
                className={cn(styles.selectDay)}
              >
                <Text className={styles.selectDayTitle}>
                  {moment(day).format("DD MMMM")}
                </Text>
                <div className="d-flex flex-column gap-2">
                  <RadioButton
                    name={`${selectId}-attendance-format-day-${day}`}
                    labelText="On site"
                    value={1}
                    onChange={(e) =>
                      onRadioButtonChange(day, +e.target.value as 1)
                    }
                    checked={!!attendanceFormatByDay[day]}
                    defaultChecked
                  />
                  <RadioButton
                    name={`${selectId}-attendance-format-day-${day}`}
                    labelText="Online"
                    value={0}
                    onChange={(e) =>
                      onRadioButtonChange(day, +e.target.value as 0)
                    }
                    checked={!attendanceFormatByDay[day]}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendanceFormatSelect;
